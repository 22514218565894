import { Space, Table, Button, message } from "antd";
import { DownloadOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Empty } from "../asssets";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";

const MedicalReportTable = ({ data }) => {
  const sendReport = (id) => {
    axios
      .get(`/patient/sendReport?billNumber=${id}`)
      .then((res) => {
        message.success("Report sent successfully");
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "name",
      key: "name",
      width: "5%",
      // height: "10px",
      render: (text, item, index) => <div>{index + 1}</div>,
    },
    {
      title: "Lab Name",
      dataIndex: "lab",
      key: "lab",
      width: "15%",
    },
    {
      title: "Test Name",
      dataIndex: "tests",
      key: "tests",
      width: "25%",
      render: (text, item, index) => (
        <div>
          {/* join with , */}
          {item?.tests?.map((item) => item).join(", ")}
          {/* {item.tests.map((item, index) => (
            <span key={index}>{item}</span>
          ))} */}
        </div>
      ),
    },
    {
      title: "Patient Name",
      dataIndex: "patient",
      key: "patient",
      width: "15%",
    },
    {
      title: "Date and Time",
      dataIndex: "reportDate",
      key: "reportDate",
      width: "15%",
      render: (text) => {
        return <div>{text ? moment(text).format("DD-MM-YYYY") : "-"}</div>;
      },
    },
    {
      title: "Entry Data",
      dataIndex: "address",
      key: "address",
      render: (_, record) => (
        <Space size="middle">
          <a
            href={!record?.isDownload ? "" : record?.reportUrl}
            target="_blank"
          >
            <Button type="primary" disabled={!record?.isDownload}>
              <DownloadOutlined /> Download
            </Button>
          </a>
          <Button
            style={{ color: "#25D366", borderColor: "#25D366" }}
            onClick={() => sendReport(record?._id)}
          >
            Get On <WhatsAppOutlined />
          </Button>
        </Space>
      ),
      width: "20%",
    },
  ];
  return (
    <>
      {data.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10%",
            marginLeft: "-5%",
          }}
        >
          <Empty />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
          }}
        >
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            size="middle"
            scroll={{ y: "60vh" }}
          />
        </div>
      )}
    </>
  );
};

export default MedicalReportTable;
